import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {

  const [pokemons, setPokemons] = useState([]);
  const [pokemonsFavoritos, setPokemonsFavoritos] = useState([]);
  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState(1);
  const [pokemonFiltered, setPokemonFiltered] = useState("");
  const [activeLoading, setActiveLoading] = useState(false);

  let resultFilter = !pokemonFiltered ?
    pokemons :
    pokemons.filter(product => product.name.toLowerCase().includes(pokemonFiltered.toLowerCase()))

  const filterPokemon = (e) => {
    e.preventDefault();
    setPokemonFiltered(e.target.value)
  }

  useEffect(() => {
    async function pokemonAPI251() {

      const { data } = await axios.get(`https://pokeapi.co/api/v2/pokemon?limit=251&offset=0`);
      setPokemons(data.results.map((pokemon, index) => ({
        ...pokemon,
        name: pokemon.name.charAt(0).toUpperCase() + pokemon.name.slice(1),
        id: index + 1,
        url: `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/dream-world/${index + 1}.svg`,
        fav: false
      })));
    }
    pokemonAPI251()
  }, []);



  const seeMoreInfo = (e) => {

    async function pokemonAPICall1() {

      const { data } = await axios.get(`https://pokeapi.co/api/v2/pokemon/${e}/`);
      const dataToSetDataModal =
      {
        id: e,
        name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
        abilities: data.abilities,
        types: data.types,
        height: data.height / 10,
        weight: data.weight / 10,
        url: `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/dream-world/${e}.svg`,
      }

      async function pokemonSpecies() {
        const { data } = await axios.get(`https://pokeapi.co/api/v2/pokemon-species/${e}/`);
        const evolutions = data.evolution_chain.url

        async function pokemonEvolutions() {
          const { data } = await axios.get(`${evolutions}`)
          const evo1 = data.chain.species.name

          let evo2 = []
          if (data.chain.evolves_to.length) {
            for (let prop in data.chain.evolves_to) {
              evo2.push(data.chain.evolves_to[prop].species.name);
            }
          } else {
            evo2 = false
          }

          let evo3 = []
          if (evo2) {
            if (data.chain.evolves_to[0].evolves_to.length) {
              for (let prop in data.chain.evolves_to) {
                evo3.push(data.chain.evolves_to[0].evolves_to[prop].species.name);
              }
            } else {
              evo3 = false
            }
          } else {
            evo3 = false
          }

          const finalDataToSetDataModal = {
            ...dataToSetDataModal,
            data_evol: data.chain,
            evolution_1: evo1,
            evolution_2: evo2,
            evolution_3: evo3,
          }
          setDataModal(finalDataToSetDataModal)
          setActiveLoading(!activeLoading)
        }
        pokemonEvolutions()
      }
      pokemonSpecies()
    }
    pokemonAPICall1()
  }


  const addFavorite = (e) => {
    pokemons[e - 1].fav = !pokemons[e - 1].fav
    setPokemons(
      pokemons.map((pokemon, index) => ({
        ...pokemon
      }))
    )
    setPokemonsFavoritos(pokemons.filter(pokemon => pokemon.fav === true))
  }


  return (

    <DataContext.Provider value={{
      pokemons,
      pokemonsFavoritos,
      addFavorite,
      modal,
      setModal,
      dataModal,
      seeMoreInfo,
      filterPokemon,
      pokemonFiltered,
      resultFilter,
      activeLoading,
      setActiveLoading
    }}>
      {children}
    </DataContext.Provider>
  )

}