import logo from '../images/logo-pokedex.png';
// import { Link } from 'react-router-dom';
import { useContext } from "react";
import { DataContext } from '../context/DataProvider';

export default function Navbar() {

    const { filterPokemon, pokemonFiltered } = useContext(DataContext)

    return (
        <div className="bg-danger navbar-expand-lg mb-4 text-white menu-estatico">

            <div className="container">
                <nav className="navbar navbar-dark">
                    <img src={logo} alt="Bienvenido a la Pokedex" className="logo-pokedex navbar-brand mx-auto mx-lg-3" />
                    {/* <div className=" navbar-collapse" id="navbarText">
                        <ul className="navbar-nav mr-auto nav-style-mobile ">
                            <li className="col-6 col-lg-7 nav-item mx-auto mx-lg-2 px-1" key="Home">
                                <Link className="nav-link btn btn-danger active px-2" to="/">
                                    <strong>First Generation</strong>
                                </Link>
                            </li>
                            <li className="col-6 nav-item mx-auto mx-lg-2 px-1" key="Favs">
                                <Link className="nav-link btn btn-danger active px-2" to="/favoritos">
                                    <strong>Favorites</strong>
                                </Link>
                            </li>
                        </ul>
                    </div> */}

                    <div className="col-12 col-lg-4 fav-counter my-3 my-lg-0">
                        <form onSubmit={filterPokemon} className="col-12 mt-1">
                            <input
                                className="form-control"
                                value={pokemonFiltered}
                                type="search"
                                placeholder="Buscar..."
                                onChange={filterPokemon}
                            />
                        </form>
                        {/* <strong>You have {pokemonsFavoritos.length} favorites Pokemon <img src={heartR} alt="ícono decorazon" /></strong> */}
                    </div>
                </nav>
            </div>
        </div>
    );
}
