import PokemonCard from './PokemonCard';
import { useContext } from "react";
import { DataContext } from '../context/DataProvider';
import PokemonListLoading from './PokemonListLoading';

export default function PokemonListScreen() {

    const { resultFilter } = useContext(DataContext)

    return (
        <div className="container" style={{ marginTop: "140px" }}>
            <h1 className='text-white mb-4 text-md-start text-center'>1st and 2nd Generation</h1>
            <div className="row">
                {resultFilter.map(pokemon => (
                    <PokemonCard
                        id={pokemon.id}
                        name={pokemon.name}
                        url={pokemon.url}
                        fav={pokemon.fav}
                        key={pokemon.id}
                    />
                )) || <PokemonListLoading />
                }
            </div>
        </div>
    );
}





