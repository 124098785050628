import { useContext } from 'react';
import { DataContext } from '../context/DataProvider';
import SeeMoreLoading from './SeeMoreLoading';


export default function SeeMore() {

    const { modal, activeLoading, setActiveLoading, setModal, dataModal } = useContext(DataContext)

    const capHy = (e) => {
        const formatedCapHy = e.replaceAll('-', ' ')
        const splitStr = formatedCapHy.split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
        }
        return splitStr.join(' ')
    }

    const justCap = (e) => {
        const splitStr = e.charAt(0).toUpperCase() + e.slice(1);
        return splitStr
    }

    return (
        <>
            {modal &&
                (
                    <>
                        {activeLoading &&
                            (
                                <div className="seemore-modal">
                                    <div className="overlay" onClick={e => {
                                        setModal(!modal)
                                        setActiveLoading(!activeLoading)
                                    }}>
                                    </div>
                                    <div className="seemore-modal-content">
                                        <div className="card">
                                            <div className='image-box-see-more'>

                                                <div className="pokemon-type types-list-pokemon" key="types">
                                                    {
                                                        dataModal.types.map(types => (
                                                            <span className={types.type.name} key={types.type.name}>{capHy(types.type.name)}</span>
                                                        ))
                                                    }
                                                </div>
                                                <img className="card-img-top w-50 mx-auto pt-2" src={dataModal.url} alt={dataModal.name} />
                                            </div>
                                            <div className="card-body textos-pokemon pb-0">
                                                <h5 className="card-title h2 text-white my-2">{dataModal.name}</h5>
                                                <p className="card-text d-none">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                                                <ul className="list-group list-group-flush">

                                                    <li className="list-group-item bg-custom-pokemon text-white list-item-custom-pokemon pokemon-type" key="height">
                                                        <div className="col-5 d-inline-block">Height: {dataModal.height} m</div>
                                                        <div className="col-5 d-inline-block">Weight: {dataModal.weight} kg</div>
                                                    </li>

                                                    <li className="list-group-item pokemon-type bg-custom-pokemon text-white list-item-custom-pokemon" key="abilities">Abilities:<br />
                                                        {
                                                            dataModal.abilities.map(abilities => (
                                                                <span className={abilities.ability.name} key={abilities.ability.name}>{capHy(abilities.ability.name)}</span>
                                                            ))
                                                        }
                                                    </li>
                                                    {dataModal.evolution_2.length && (
                                                        <li className="list-group-item pokemon-type bg-custom-pokemon text-white list-item-custom-pokemon" key="evolutions">Evolutions:<br />
                                                            <span className='' key={dataModal.evolution_1}>
                                                                {justCap(dataModal.evolution_1)}
                                                            </span> to
                                                            {
                                                                dataModal.evolution_2.map(evolution => (
                                                                    <span className={evolution} key={evolution}>
                                                                        {justCap(evolution)}
                                                                    </span>
                                                                ))
                                                            }
                                                            <br />

                                                            {dataModal.evolution_3.length && (
                                                                <>
                                                                    <span className='' key="evolusion 2">
                                                                        {justCap(dataModal.evolution_2[0])}
                                                                    </span> to
                                                                    <span className='' key="evolusion 3">
                                                                        {justCap(dataModal.evolution_3[0])}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </li>)}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!activeLoading &&
                            (
                                <SeeMoreLoading />
                            )}
                    </>
                )}
        </>
    );
}
