import PokemonCard from './PokemonCard';
import { useContext } from "react";
import { DataContext } from '../context/DataProvider';
import noFav from '../images/no-fav.png';

export default function PokemonListScreen() {

    const { pokemonsFavoritos } = useContext(DataContext)

    return (
        <div className="container" style={{ marginTop: "140px" }}>
            <h1 className='text-white mb-4 text-md-start text-center'>Favorites List</h1>
            <div className="row">
                {
                    pokemonsFavoritos.length === 0 ?
                        <div className='text-center'>
                        <img src={noFav} alt="snorlax durmiendo" />
                        <h2 className='text-white'>Aún no tienes favoritos.</h2>
                        </div>
                        : console.log('')
                }
                {pokemonsFavoritos.map(pokemon => (
                    <PokemonCard
                        id={pokemon.id}
                        name={pokemon.name}
                        url={pokemon.url}
                        fav={pokemon.fav}
                        key={pokemon.id}
                    />
                ))}
            </div>
        </div>
    );
}





