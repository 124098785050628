import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function SeeMoreLoading() {

    return (
        <SkeletonTheme baseColor="#3d5976" highlightColor="#456587">
            <div className="seemore-modal">
                <div className="overlay"></div>
                <div className="seemore-modal-content">
                    <div className="card">
                        <div className='text-center mx-auto mt-2 mb-4 pb-1'>
                            <Skeleton count={1} height={180} width={200} baseColor="#e8e8e866" highlightColor="#eeeeee66" />
                        </div>
                        <div className="card-body textos-pokemon pb-0">
                            <h5 className="card-title h2 text-white my-2 px-3">
                                <Skeleton count={1} />
                            </h5>

                            <ul className="list-group list-group-flush">

                                <li className="list-group-item bg-custom-pokemon text-white list-item-custom-pokemon " key="height">
                                    <div className="col-12 d-inline-block">
                                        <Skeleton count={1} height={16} />
                                    </div>
                                </li>

                                <li className="list-group-item bg-custom-pokemon text-white list-item-custom-pokemon" key="abilities">
                                    <Skeleton count={5} height={16} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
}
