import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function PokemonListLoading() {

    return (
        <SkeletonTheme baseColor="#3d5976" highlightColor="#456587">
            <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 text-center">
                <div className="col-12 col-xs-12 col-md-12 col-lg-12 card mb-3 mx-0">
                    <Skeleton count={1} height={240} baseColor="#e8e8e866" highlightColor="#eeeeee66" />
                </div>
            </div>
        </SkeletonTheme>
    );
}
